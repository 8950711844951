export default [
    {
        name: "참여 아동 정보",
        icon: "user-friends",
        to: "/master/main"
    },
    {
        name: "아동 평가 정보",
        icon: "clipboard-list",
        to: "/master/evaluationInfo"
    },
    {
        name: "문의사항 관리",
        icon: "comment-dots",
        to: "/master/qnaManage"
    },

];
