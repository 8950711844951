<template>
  <div class="layout">
    <div class="modal-cover" v-show="isShowModal"></div>
    <mdb-side-nav-2
        :value="true"
        :data="navigation"
        slim
        :slim-collapsed="collapsed"
        @toggleSlim="collapsed = $event"
        sidenav-class="unique-color-dark"
        color="white"
        @itemClick="selectItem"
    >
      <div slot="header">
        <div class="p-1">
          <img
              src="@/assets/images/IA/logo_main_admin.png"
              class="img-fluid"
              alt="null"/>
        </div>
        <hr/>
      </div>
      <div slot="content" class="mt-5 d-flex justify-content-center">
        <mdb-btn tag="a" class="mx-0" size="sm" transparent floating
                 :icon="collapsed ? 'chevron-right' : 'chevron-left'" icon-class="white-text"
                 @click="collapseLock"></mdb-btn>
      </div>

      <mdb-navbar
          slot="nav"
          tag="div"
          :toggler="false"
          position="top"
          dark
          color="unique-color-dark"
      >
        <mdb-navbar-nav class="nav-flex-icons" right>

          <mdb-nav-item waves-fixed icon="user-circle" class="userName">
            <span class="ml-1 ">{{ userName }}</span>
          </mdb-nav-item>
          <div class="width40"></div>
          <mdb-nav-item waves-fixed icon="sign-out-alt" class="logout" @click="isShowModal = true">
            <span class="ml-1 ">로그아웃</span>
          </mdb-nav-item>
        </mdb-navbar-nav>
      </mdb-navbar>

      <div slot="main" class="main">
        <router-view class="main-contents"></router-view>
      </div>
    </mdb-side-nav-2>
    <LogoutDialog v-if="isShowModal" @callBackModal="callBackModal"/>
  </div>
</template>

<script>
import {mdbSideNav2, mdbBtn, waves, mdbNavbar, mdbNavItem, mdbNavbarNav,} from "mdbvue";
import adminNavigation from "../../assets/data/adminNavigation";
import firebase from "firebase/app";
import LogoutDialog from "@/views/common/LogoutDialog";

export default {
  name: "MasterView",
  components: {
    LogoutDialog,
    mdbSideNav2,
    mdbBtn,
    mdbNavbar,
    mdbNavItem,
    mdbNavbarNav,
  },
  data() {
    return {
      lock: false,
      show: true,
      collapsed: false,
      navigation: adminNavigation,
      userName: '관리자님',
      isShowModal: false
    };
  },
  methods: {
    selectItem() {
      if (!this.lock) {
        // this.collapsed = true
      }
    },

    collapseLock() {
      this.collapsed = !this.collapsed
      this.lock = !this.collapsed;
    },

    callBackModal: function (index) {
      const self = this;
      if (index === 1) {
        self.isShowModal = false
      } else {
        self.isShowModal = false
        firebase.auth().signOut();
      }
    },
  },
  mixins: [waves]
};
</script>

<style scoped>

/deep/ .unique-color-dark {
  background: #2B2346 !important;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25) !important;
}

.navbar i {
  cursor: pointer;
  color: white;
}

.width40 {
  width: 40px;
}

.main-contents {
  margin-top: 56px;
  padding-bottom: 56px;
  min-height: calc(100vh - 56px);
}

.p-1 img {
  width: 200px;
  padding-top: 56px;
}

/deep/ .userName .navbar-link {
  padding-right: 0 !important;
}

/deep/ .logout .navbar-link {
  padding-left: 0 !important;
}
</style>
